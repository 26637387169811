import "./contact.scss";
import { AiOutlineMail } from "react-icons/ai";
import { BsTelegram } from "react-icons/bs";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import { IoLogoLinkedin } from "react-icons/io";

import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_aaevl8t",
        "template_0js0b5o",
        form.current,
        "c6O28Z89KPgypi9YQ"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Message sent!");
        },
        (error) => {
          console.log(error.text);
        }
      );

    form.current.reset();
  };

  return (
    <div className="contact" id="contact">
      <h1>Contact</h1>
      <div className="wrapper">
        <div className="left">
          <div className="item">
            <AiOutlineMail className="item-icon" />
            <div className="item-value">harmontchew@gmail.com</div>
            <a
              href="mailto:harmontchew@gmail.com"
              target="_blank"
              className="item-button"
              rel="noopener noreferrer"
            >
              Email me!
            </a>
          </div>
          <div className="item">
            <BsTelegram className="item-icon" />
            <div className="item-value">@harmon_chew</div>
            <a
              href="https://t.me/harmon_chew"
              target="_blank"
              className="item-button"
              rel="noopener noreferrer"
            >
              Message me!
            </a>
          </div>
          <div className="item">
            <IoLogoLinkedin
              className="item-icon"
              style={{ fontSize: "25px" }}
            />
            <div className="item-value">Harmon Chew</div>
            <a
              href="https://www.linkedin.com/in/harmon-chew"
              target="_blank"
              className="item-button"
              rel="noopener noreferrer"
            >
              Find me!
            </a>
          </div>
        </div>
        <div className="right">
          <h3>Send me a message!</h3>
          <form ref={form} onSubmit={sendEmail}>
            <input
              type="text"
              name="user_name"
              placeholder="Your Full Name"
              className="input"
            />
            <input
              type="email"
              name="user_email"
              placeholder="Your Email"
              className="input"
            />
            <textarea name="message" placeholder="Your Message" />
            <input type="submit" value="Send Message" className="submit" />
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Contact;
