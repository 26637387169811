import React, { useState } from "react";
import Navbar from "./components/navbar/Navbar";
import Intro from "./components/intro/Intro";
import Portfolio from "./components/portfolio/Portfolio";
// import Testimonials from "./components/testimonials/Testimonials";
import Contact from "./components/contact/Contact";
import Experience from "./components/experience/Experience";
import "./app.scss";
import Menu from "./components/menu/Menu";
import About from "./components/about/About";

const App = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="app">
      <Navbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <div className="sections">
        <Intro />
        <About />
        <Experience />
        <Portfolio />
        {/* <Testimonials /> */}
        <Contact />
      </div>
    </div>
  );
};

export default App;
