import React from "react";
import "./navbar.scss";
import { BsTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";

const Navbar = ({ menuOpen, setMenuOpen }) => {
  return (
    <div className={"navbar " + (menuOpen && "active")}>
      <div className="wrapper">
        <div className="left">
          <a href="#intro" className="logo">
            Harmon Chew
          </a>
          <div className="item">
            <div className="item-logo">
              <MdEmail />
            </div>
            <div className="item-value">harmontchew@gmail.com</div>
          </div>
          <div className="item">
            <div className="item-logo">
              <BsTelephoneFill />
            </div>
            <div className="item-value">+65 9368 6846</div>
          </div>
        </div>
        <div className="right">
          <div
            className="hamburger"
            onClick={() => setMenuOpen((prev) => !prev)}
          >
            <span className="line1"></span>
            <span className="line2"></span>
            <span className="line3"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
