import React from "react";
import "./about.scss";

const About = () => {
  return (
    <div className="about" id="about">
      <h1>About</h1>
      <div className="wrapper">
        <div className="leftSide">
          <div class="clock">
            <div class="top"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="center"></div>
            <div class="shadow"></div>
            <div class="hour"></div>
            <div class="minute"></div>
            <div class="second"></div>
          </div>
        </div>
        <div className="rightSide">
          <div className="desc">
            I'm Harmon, a passionate developer that loves to create. I am
            experienced in developing full stack web applications that are
            responsive and intuitive. I also enjoy learning new technologies and
            sharing my passion with others.
          </div>
          <a href="#contact" className="contact">
            Let's work together!
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;
