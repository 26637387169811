import React, { useEffect, useRef } from "react";
import "./intro.scss";
import { AiOutlineArrowDown } from "react-icons/ai";
import { init } from "ityped";

const Intro = () => {
  const textRef = useRef();

  useEffect(() => {
    init(textRef.current, {
      strings: ["Full Stack Developer", "Designer", "Content Creator"],
      backDelay: 1000,
      typeSpeed: 50,
    });
  }, []);

  return (
    <div className="intro" id="intro">
      <div className="left">
        <div className="img-container">
          <img src="assets/me.png" alt="" />
        </div>
      </div>
      <div className="right">
        <div className="wrapper">
          <h2>Hi There, I'm</h2>
          <h1 className="name">Harmon Chew</h1>
          <h3>
            <span ref={textRef}></span>
          </h3>
        </div>
        <a href="#about">
          <AiOutlineArrowDown className="img" />
        </a>
      </div>
    </div>
  );
};

export default Intro;
