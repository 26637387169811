export const featuredPortfolio = [
  {
    name: "Tenzies",
    img: "https://i.ibb.co/NtD6vfS/image.png",
    desc: "Plain CSS",
    github: "https://github.com/HarmonChew/Tenzies",
    liveDemo: "https://harmonchewtenzies.netlify.app/",
  },
  {
    name: "Wordle",
    img: "https://i.ibb.co/Zz6mt5W/image.png",
    desc: "Context API",
    github: "https://github.com/HarmonChew/wordle0.5",
    liveDemo: "https://harmonchewwordle.netlify.app/",
  },
  {
    name: "Dashboard",
    img: "https://i.ibb.co/SVV4XP0/image.png",
    desc: "React Router, Sass, Recharts",
    github: "https://github.com/HarmonChew/react-dashboard",
    liveDemo: "https://harmonchewdashboard.netlify.app/",
  },
  {
    name: "Bebster",
    img: "https://i.ibb.co/hWRssXB/image.png",
    desc: "MERN, Axios, Redux, Stripe, Redux Persist, Styled Components, CryptoJS, JWT",
    github: "https://github.com/HarmonChew/Bebster",
  },
  {
    name: "Blog",
    img: "https://i.ibb.co/3dxsyRK/image.png",
    desc: "NextJS, Firebase, React Markdown,",
    github: "https://github.com/HarmonChew/nextblog",
  },
];
export const webPortfolio = [
  {
    name: "Tenzies",
    img: "https://i.ibb.co/NtD6vfS/image.png",
    desc: "Plain CSS",
    github: "https://github.com/HarmonChew/Tenzies",
    liveDemo: "https://harmonchewtenzies.netlify.app/",
  },
  {
    name: "Wordle",
    img: "https://i.ibb.co/Zz6mt5W/image.png",
    desc: "Context API",
    github: "https://github.com/HarmonChew/wordle0.5",
    liveDemo: "https://harmonchewwordle.netlify.app/",
  },
  {
    name: "Dashboard",
    img: "https://i.ibb.co/SVV4XP0/image.png",
    desc: "React Router, Sass, Recharts",
    github: "https://github.com/HarmonChew/react-dashboard",
    liveDemo: "https://harmonchewdashboard.netlify.app/",
  },
  {
    name: "Bebster",
    img: "https://i.ibb.co/hWRssXB/image.png",
    desc: "MERN, Axios, Redux, Stripe, Redux Persist, Styled Components, CryptoJS, JWT",
    github: "https://github.com/Bebster",
  },
  {
    name: "Blog",
    img: "https://i.ibb.co/3dxsyRK/image.png",
    desc: "NextJS, Firebase, React Markdown,",
    github: "https://github.com/nextblog",
  },
];

export const designPortfolio = [];
