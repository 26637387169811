import React, { useEffect, useState } from "react";
import PortfolioList from "../portfolioList/PortfolioList";
import "./portfolio.scss";
import {
  featuredPortfolio,
  webPortfolio,
  designPortfolio,
} from "../../portfolioData";

const Portfolio = () => {
  const [selected, setSelected] = useState("featured");
  const [portfolio, setPortfolio] = useState([featuredPortfolio]);

  useEffect(() => {
    switch (selected) {
      case "featured":
        setPortfolio(featuredPortfolio);
        break;
      case "web":
        setPortfolio(webPortfolio);
        break;
      case "design":
        setPortfolio(designPortfolio);
        break;
      default:
        setPortfolio(featuredPortfolio);
        break;
    }
  }, [selected]);

  const list = [
    {
      id: "featured",
      title: "Featured",
    },
    {
      id: "web",
      title: "Web App",
    },
    {
      id: "design",
      title: "Design",
    },
  ];

  return (
    <div className="portfolio" id="portfolio">
      <h1>Porfolio</h1>
      <ul>
        {list.map((item) => (
          <PortfolioList
            title={item.title}
            key={item.id}
            id={item.id}
            setSelected={setSelected}
            active={selected === item.id}
          />
        ))}
      </ul>

      <div className="container">
        {portfolio.map((item) => {
          return (
            <div className="item">
              <img src={item.img} alt="" className="img" />
              <div className="desc">
                <h3>{item.name}</h3>
                <p>{item.desc}</p>
                <div className="buttons">
                  <a
                    href={item.github}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn"
                  >
                    GitHub
                  </a>
                  {item.liveDemo && (
                    <a
                      href={item.liveDemo}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn"
                    >
                      Demo
                    </a>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Portfolio;
