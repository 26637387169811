import React from "react";
import "./experience.scss";
import { TiTickOutline } from "react-icons/ti";

const Experience = () => {
  return (
    <div className="experience" id="experience">
      <h1>Experience</h1>
      <div className="wrapper">
        <div className="left">
          <h2>Front End</h2>
          <div className="content">
            <div className="item">
              <div className="icon">
                <TiTickOutline />
              </div>
              <div className="item-content">
                <div className="item-title">Javascript</div>
                <div className="item-value">Experienced</div>
              </div>
            </div>
            <div className="item">
              <div className="icon">
                <TiTickOutline />
              </div>
              <div className="item-content">
                <div className="item-title">HTML</div>
                <div className="item-value">Experienced</div>
              </div>
            </div>
            <div className="item">
              <div className="icon">
                <TiTickOutline />
              </div>
              <div className="item-content">
                <div className="item-title">CSS</div>
                <div className="item-value">Experienced</div>
              </div>
            </div>
            <div className="item">
              <div className="icon">
                <TiTickOutline />
              </div>
              <div className="item-content">
                <div className="item-title">React</div>
                <div className="item-value">Experienced</div>
              </div>
            </div>
            <div className="item">
              <div className="icon">
                <TiTickOutline />
              </div>
              <div className="item-content">
                <div className="item-title">Angular</div>
                <div className="item-value">Intermediate</div>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <h2>Back End</h2>
          <div className="content">
            <div className="item">
              <div className="icon">
                <TiTickOutline />
              </div>
              <div className="item-content">
                <div className="item-title">Java</div>
                <div className="item-value">Experienced</div>
              </div>
            </div>
            <div className="item">
              <div className="icon">
                <TiTickOutline />
              </div>
              <div className="item-content">
                <div className="item-title">Python</div>
                <div className="item-value">Intermediate</div>
              </div>
            </div>
            <div className="item">
              <div className="icon">
                <TiTickOutline />
              </div>
              <div className="item-content">
                <div className="item-title">SQL</div>
                <div className="item-value">Experienced</div>
              </div>
            </div>
            <div className="item">
              <div className="icon">
                <TiTickOutline />
              </div>
              <div className="item-content">
                <div className="item-title">Jakarta EE</div>
                <div className="item-value">Experienced</div>
              </div>
            </div>
            <div className="item">
              <div className="icon">
                <TiTickOutline />
              </div>
              <div className="item-content">
                <div className="item-title">NodeJS</div>
                <div className="item-value">Intermediate</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a className="resume" href="assets/HarmonResume.docx" download>
        Full Resume
      </a>
    </div>
  );
};

export default Experience;
